import React from 'react';
import { graphql, Link } from 'gatsby';
import kebabCase from 'lodash/kebabCase';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { Layout } from '@components';
import { IconBookmark } from '@components/icons';
import './weatherpro.css';

const StyledMainContainer = styled.main`
  & > header {
    margin-bottom: 100px;
    text-align: center;

    a {
      &:hover,
      &:focus {
        cursor: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='40' height='48' viewport='0 0 100 100' style='fill:black;font-size:24px;'><text y='50%'>⚡</text></svg>")
            20 0,
          auto;
      }
    }
  }

  footer {
    ${({ theme }) => theme.mixins.flexBetween};
    width: 100%;
    margin-top: 20px;
  }
`;
const StyledGrid = styled.ul`
  ${({ theme }) => theme.mixins.resetList};
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 15px;
  margin-top: 50px;
  position: relative;

  @media (max-width: 1080px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  }
`;
const StyledPost = styled.li`
  transition: var(--transition);
  cursor: default;

  @media (prefers-reduced-motion: no-preference) {
    &:hover,
    &:focus-within {
      .post__inner {
        transform: translateY(-7px);
      }
    }
  }

  a {
    position: relative;
    z-index: 1;
  }

  .post__inner {
    ${({ theme }) => theme.mixins.boxShadow};
    ${({ theme }) => theme.mixins.flexBetween};
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    height: 100%;
    padding: 2rem 1.75rem;
    border-radius: var(--border-radius);
    transition: var(--transition);
    background-color: var(--light-navy);

    header,
    a {
      width: 100%;
    }
  }

  .post__icon {
    ${({ theme }) => theme.mixins.flexBetween};
    color: var(--green);
    margin-bottom: 30px;
    margin-left: -5px;

    svg {
      width: 40px;
      height: 40px;
    }
  }

  .post__title {
    margin: 0 0 10px;
    color: var(--lightest-slate);
    font-size: var(--fz-xxl);

    a {
      position: static;

      &:before {
        content: '';
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  .post__desc {
    color: var(--light-slate);
    font-size: 17px;
  }

  .post__date {
    color: var(--light-slate);
    font-family: var(--font-mono);
    font-size: var(--fz-xxs);
    text-transform: uppercase;
  }

  ul.post__tags {
    display: flex;
    align-items: flex-end;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      color: var(--green);
      font-family: var(--font-mono);
      font-size: var(--fz-xxs);
      line-height: 1.75;

      &:not(:last-of-type) {
        margin-right: 15px;
      }
    }
  }
`;

const PensievePage = ({ location, data }) => {
  const posts = data.allMarkdownRemark.edges;

  return (
    <Layout location={location}>
      <Helmet title="Wire Frames" />

      <StyledMainContainer>
        <header>
          <h1 className="big-heading">Weather Pro</h1>
          <p className="divcolor">
            Spearheading the design of a new weather app for users with the latest features.
          </p>

          <StaticImage
            className="imgxsa"
            src="../../images/test1.jpeg"
            width={1000}
            quality={100}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Headshot"
          />
        </header>
        <div className="">
          <h3>PROCESS</h3>
        </div>
        <StaticImage
          className="imgxsa"
          src="../../images/processweather.png"
          quality={100}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
        <div className="header-space-above-margin ">
          <h3>WEATHER PRO APP</h3>
        </div>
        <p className="divcolor header-space-below-padding">The main thing is the weather.</p>
        <StaticImage
          className=""
          src="../../images/weathertest.jpeg"
          width={1000}
          quality={100}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
        <p className="header-space-above-padding ">
          This app provides accurate weather information based on location and allows to add
          favourite cities of the user. This app will also provide rain forecast, humidity
          information, maximum and minimum temperatures of the day and hourly temperature for a
          location. The forecasts are accentuated by images for clear comprehension.
        </p>
        <div className="header-space-above-margin ">
          <h3>OUR PROBLEM</h3>
          <p className="divcolor ">
            The users across the globe required a weather app with weather details of their
            favourite cities,a click away rather than locating it from the map every time and also
            the feature to keep track of the weather parameters of their favourite cities.
          </p>
          <p className=" ">
            How might we design an interface that is unique,but still connects to the users that is
            compact enough to give users focus on the core problem ?
          </p>
        </div>
        <div className="header-space-above-margin ">
          <h3 className="header-space-above-padding ">GETTING ORIENTED</h3>
          <p className="divcolor ">
            After our first meeting with some of the founders,my UI design partner and i delved into
            world of Weather Pro to gather insights and an understanding of the App.
          </p>
          <StaticImage
            className="imgxsa"
            src="../../images/weatherthird.jpg"
            width={1000}
            height={800}
            quality={100}
            formats={['AUTO', 'WEBP', 'AVIF']}
            alt="Headshot"
          />
        </div>
        <div className="divcolor header-space-above-margin ">
          <p>
            The various stages of design process from the starting stage to the final design which
            include personas,problem statement,insights,usability studies,low fidelity wireframe and
            prototypes, high fidelity prototypes and mockups are classified and listed below{' '}
          </p>
        </div>
        <StyledGrid>
          {posts.length > 0 &&
            posts.map(({ node }, i) => {
              const { frontmatter } = node;
              const { title, description, slug, date, tags } = frontmatter;
              const formattedDate = new Date(date).toLocaleDateString();

              return (
                <StyledPost key={i}>
                  <div className="post__inner">
                    <header>
                      <div className="post__icon">
                        <IconBookmark />
                      </div>
                      <h5 className="post__title">
                        <Link to={slug}>{title}</Link>
                      </h5>
                      <p className="post__desc">{description}</p>
                    </header>

                    <footer>
                      <span className="post__date">{formattedDate}</span>
                      <ul className="post__tags">

                      </ul>
                    </footer>
                  </div>
                </StyledPost>
              );
            })}
        </StyledGrid>
        <h3 className="header-space-above-margin">Final Thoughts</h3>
        <p className="divcolor header-space-below-padding">Smiles all around</p>
        <div className="  ">
          <p>
            After our presentation, Client was ecstatic and absolutely loved the new app and brand
            we had designed for him. Our team's design was also chosen to be developed by the App
            Development Team. As a result, I also prepared all the hand-off materials and answered any
            questions developers had about the design or functionality of the app.
          </p>
        </div>
        <StaticImage
          className="imgxsa"
          src="../../images/weatherfinal.jpg"
          width={1000}
          quality={100}
          formats={['AUTO', 'WEBP', 'AVIF']}
          alt="Headshot"
        />
        <div className="header-space-above-padding">
          <p>
            “Great. Just great. Thank you so much. I can see why you wanted to work on a
            new brand and I love the way it turned out. Could you please send me all the photos as
            soon as possible?"
          </p>
        </div>
        <p className="divcolor">Alex Ferguson,Owner</p>
      </StyledMainContainer>
    </Layout>
  );
};

PensievePage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

export default PensievePage;

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/content/posts/weatherpro/" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            description
            slug
            date
            tags
            draft
          }
          html
        }
      }
    }
  }
`;
